import React from 'react'
import { IconContext } from "react-icons";
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { SiGmail } from 'react-icons/si'

import * as styles from '../components/styles.module.scss'


const Title= () => {
	return (
		<div id='about' className={styles.titlecontainer}>
			<div className={styles.titlecontent}>
				<h1>Hi, I'm <span>Damien Kihe</span></h1>
				<p>I am a developer based in <span>Honolulu, Hawaii</span></p>
				<p>Hit me up and lets make something cool</p>
				<IconContext.Provider value={{className: styles.icons}}>
					<a onClick={() => window.location = "https://github.com/dkihe"}><FaGithub size={'50'} /></a>
					<a onClick={() => window.location = "https://linkedin.com/in/damienkihe"}><FaLinkedin size={'50'} /></a>
					<a onClick={() => window.location.href = "mailto:dnkkihe@gmail.com"}><SiGmail size={'50'} /></a>
				</IconContext.Provider>
			</div>
		</div>
	)
}

export default Title