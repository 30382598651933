// extracted by mini-css-extract-plugin
export const mediaqueries = "styles-module--mediaqueries--2jEU6";
export const headercontainer = "styles-module--headercontainer--2y6sf";
export const innerheader = "styles-module--innerheader--2Ii4S";
export const title = "styles-module--title--1nTMn";
export const titlecontainer = "styles-module--titlecontainer--3ohsM";
export const titlecontent = "styles-module--titlecontent--3Lu1p";
export const icons = "styles-module--icons--3MeNA";
export const portfoliocontainer = "styles-module--portfoliocontainer--1dChx";
export const portfoliocontent = "styles-module--portfoliocontent--11Rs7";
export const projectcontainer = "styles-module--projectcontainer--3U_uR";
export const innercontainer = "styles-module--innercontainer--2bsuy";
export const projectimg = "styles-module--projectimg--2e77R";
export const projectcontent = "styles-module--projectcontent--21h0M";
export const description = "styles-module--description--lQtqw";
export const projectlinks = "styles-module--projectlinks--1Ecqz";
export const iconscontainer = "styles-module--iconscontainer--2abaf";
export const projecticons = "styles-module--projecticons--3Catv";